import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  NgxMatTimepickerComponent,
  NgxMatTimepickerDirective,
} from 'ngx-mat-timepicker';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  MatFormField,
  MatFormFieldModule,
  MatSuffix,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';


import { MainInterceptor } from './interceptors/main-interceptor.interceptor';
import { NotificationService } from './services/notification.service';
import { SpinnerDirective } from './directives/spinner.directive';
import { RatingComponent } from './components/rating/rating.component';
import { ServiceSelectComponent } from './components/service-select/service-select.component';
import { GlobalErrorService } from './services/global-error.service';
import { ContactPhoneInputComponent } from '@/business/components/contact-phone-input/contact-phone-input.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    NgSelectModule,
    MatDatepickerModule,
    NgxMatTimepickerComponent,
    NgxMatTimepickerDirective,
    MatSnackBarModule,
    NgxMatIntlTelInputComponent,
    MatFormField,
    MatInput,
    MatSuffix,
    MatSelect,
    MatOption,
    MatFormFieldModule,
    AutocompleteLibModule,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
  ],
  providers: [
    provideNativeDateAdapter(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MainInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorService,
    },
    NotificationService,
  ],
  exports: [
    ContactPhoneInputComponent,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    TranslateModule,
    NgSelectModule,
    NgxMatTimepickerComponent,
    NgxMatTimepickerDirective,
    MatDatepickerModule,
    SpinnerDirective,
    NgxMatIntlTelInputComponent,
    MatSnackBarModule,
    MatFormField,
    MatInput,
    MatSuffix,
    MatFormFieldModule,
    AutocompleteLibModule,
    MatAutocompleteModule,
    RatingComponent,
    MatSelect,
    MatOption,
    ServiceSelectComponent,
  ],
  declarations: [
    SpinnerDirective,
    RatingComponent,
    ContactPhoneInputComponent,
    ServiceSelectComponent,
  ],
})
export class ShareModule {}
