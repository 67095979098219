<section class="navigation" *ngIf="!isHidden">
  <ul>
    <li><a routerLink="/dashboard" routerLinkActive="active">Dashboard</a></li>
    <li><a routerLink="/owners" routerLinkActive="active">Owners</a></li>
    <li><a routerLink="/business" routerLinkActive="active">Business</a></li>
    <li><a routerLink="/tags" routerLinkActive="active">Services</a></li>
    <li>
      <a routerLink="/account-types" routerLinkActive="active">Account Types</a>
    </li>
    <li><a routerLink="/jobs" routerLinkActive="active">Jobs</a></li>
    <li></li>
    <li><a routerLink="/pages" routerLinkActive="active">Pages</a></li>
    <li>
      <a routerLink="/claims" routerLinkActive="active">Claim Requests</a>
    </li>
    <li><a routerLink="/reviews" routerLinkActive="active">Reviews</a></li>
    <li>
      <a routerLink="/translation" routerLinkActive="translation"
        >Translation</a
      >
    </li>
    <!--    <li><a routerLink="/manage" routerLinkActive="active">Manage</a></li>-->
  </ul>
</section>
